
import Vue, { PropType } from "vue";

import { mdiContentSave } from "@mdi/js";
import { Building, Lock } from "@/interfaces";

import http from "@/http";

export interface DataType {
  menu: boolean;
  priority: number;
  type: string;
  can_install: boolean;
  valid: boolean;
  confirmed_at?: Date;
  note: string;
}

export default Vue.extend({
  props: {
    lock: {
      type: Object as PropType<Lock>,
      required: true
    }
  },
  data(): DataType {
    return {
      menu: false,
      priority: 3,
      type: "その他",
      can_install: false,
      valid: false,
      confirmed_at: undefined,
      note: ""
    };
  },
  computed: {
    items() {
      return ["MIWA", "GOAL", "ショウワ", "ALPHA", "オプナス", "その他"];
    },
    options() {
      return [
        {
          text: "最低",
          value: 1
        },
        {
          text: "低",
          value: 2
        },
        {
          text: "普通",
          value: 3
        },
        {
          text: "高",
          value: 4
        },
        {
          text: "最高",
          value: 5
        }
      ];
    }
  },
  mounted() {
    const { type, can_install, confirmed_at, note, priority } = this.lock;

    this.type = type;
    this.priority = priority;
    this.can_install = can_install;
    this.confirmed_at = confirmed_at;
    this.note = note;
  },
  methods: {
    submit() {
      this.$emit("save", {
        type: this.type,
        priority: this.priority,
        can_install: this.can_install,
        confirmed_at: this.confirmed_at,
        note: this.note
      });
    }
  }
});

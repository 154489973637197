
import Vue, { PropType } from "vue";

import MyEditor from "@/components/lock/MyEditor.vue";
import { Building, Lock } from "@/interfaces";

import http from "@/http";

export interface DataType {
  menu: boolean;
  type: string;
  can_install: boolean;
  valid: boolean;
  confirmed_at: string;
  note: "";
}

export default Vue.extend({
  components: {
    MyEditor
  },
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true
    }
  },
  methods: {
    async save(e: Lock) {
      if (!this.building.lock) {
        return;
      }

      const url = `locks/${this.building.lock.id}`;

      await http.put(url, {
        ...e
      });

      await this.$router.push(`/building/${this.building.id}/lock`);
    }
  }
});
